.savetiles {
  display: none;
}

.gg-track {
  --ggs: 1.5;
  left: 10px;
  top: 10px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 10px;
  border: 2px solid transparent;
  box-shadow:
    0 0 0 2px,
    inset 0 0 0 10px;
  border-radius: 100px;
}
.gg-track::after,
.gg-track::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
}
.gg-track::before {
  border-left: 4px solid;
  border-right: 4px solid;
  width: 18px;
  height: 2px;
  left: -6px;
  top: 2px;
}
.gg-track::after {
  width: 2px;
  height: 18px;
  border-top: 4px solid;
  border-bottom: 4px solid;
  left: 2px;
  top: -6px;
}

.elevation-div {
  height: 25%;
  font:
    12px/1.5 'Helvetica Neue',
    Arial,
    Helvetica,
    sans-serif;
}

.lightblue-theme.elevation-control.elevation .background {
  background-color: rgb(255, 255, 255);
}

.lightblue-theme.elevation-control.elevation .axis line,
.lightblue-theme.elevation-control.elevation .axis path {
  stroke: #000;
}

.lightblue-theme.elevation-control.elevation .grid .tick line {
  stroke: rgba(0, 0, 0, 0.2);
}

.lightblue-theme.elevation-control.elevation .mouse-drag {
  fill: #aa397d3d;
}

.lightblue-theme.elevation-control.elevation .area {
  fill: #aa397d94 !important;
  stroke: #aa397d !important;
}

#details_cover_image,
#touristicContent_cover_image {
  height: 100%;
}

.menu-download {
  z-index: 299;
}

.over-all {
  z-index: 10001;
}

/* Fix Safari issue related to <summary> / <details> arrow */
details > summary.list-none::-webkit-details-marker,
details > summary.list-none::marker {
  display: none;
}

.custo-flatpage-cover {
  max-height: calc(100vh - theme('spacing.desktopHeader'));
}

/* Flatpage WYSIWYG */
.custo-page-WYSIWYG a:not(.custo-suggestions a, .button-link) {
  @apply text-primary1 transition-colors hover:text-primary1-light focus:text-primary1-light;
}

.custo-page-WYSIWYG h1:not(.custo-suggestions h1) {
  @apply text-H2 font-bold desktop:text-H1;
}

.custo-page-WYSIWYG h2:not(.custo-suggestions h2) {
  @apply text-H3 font-bold desktop:text-H2;
}

.custo-page-WYSIWYG h3:not(.custo-suggestions h3),
.custo-page-WYSIWYG h4:not(.custo-suggestions h4) {
  @apply text-H4 font-bold desktop:text-H3;
}

.custo-page-WYSIWYG p:not(.custo-suggestions p) {
  @apply mb-4 desktop:mb-6;
}

.custo-page-WYSIWYG h1:not(.custo-suggestions h1),
.custo-page-WYSIWYG h2:not(.custo-suggestions h2),
.custo-page-WYSIWYG h3:not(.custo-suggestions h3) {
  @apply clear-both;
}

.custo-page-WYSIWYG h1:not(.custo-suggestions h1),
.custo-page-WYSIWYG h2:not(.custo-suggestions h2),
.custo-page-WYSIWYG h3:not(.custo-suggestions h3),
.custo-page-WYSIWYG h4:not(.custo-suggestions h4),
.custo-page-WYSIWYG h5:not(.custo-suggestions h5) {
  @apply my-4 desktop:my-10;
}

.custo-page-WYSIWYG h6:not(.custo-suggestions h6) {
  @apply my-4 desktop:my-8;
}

.custo-page-WYSIWYG img:not(.custo-suggestions img) {
  @apply mx-1;
}

.custo-page-WYSIWYG blockquote:not(.custo-suggestions blockquote) {
  @apply flex gap-2 text-greyDarkColored/75 italic;
}

.custo-page-WYSIWYG blockquote:not(.custo-suggestions blockquote) p:last-child {
  @apply mb-0;
}

.custo-page-WYSIWYG blockquote:not(.custo-suggestions blockquote)::before {
  content: "“";
  @apply text-5xl;
}

.custo-page-WYSIWYG iframe:not(.custo-suggestions iframe) {
  @apply my-8 w-full h-auto desktop:my-12 aspect-video;
}

.custo-page-WYSIWYG b:not(.custo-suggestions b),
.custo-page-WYSIWYG strong:not(.custo-suggestions strong) {
  @apply font-bold;
}

.custo-page-WYSIWYG em:not(.custo-suggestions em) {
  @apply text-P2 italic desktop:text-P1;
}

.custo-page-WYSIWYG ul:not(.custo-suggestions ul) {
  @apply my-4;
}

.custo-page-WYSIWYG ul:not(.custo-suggestions ul) > li {
  @apply pl-4 list-inside list-disc desktop:mb-2;
}

.custo-page-WYSIWYG .custo-suggestions {
  @apply clear-both mt-6 desktop:mt-10 w-full;
}

.custo-page-WYSIWYG .information {
  @apply clear-both py-4 px-10 rounded-lg bg-greySoft;
}

.custo-page-WYSIWYG .image {
  @apply clear-both overflow-hidden desktop:mb-8;
}

.custo-page-WYSIWYG .image:not(.align-left):not(.align-right) img {
  @apply w-full;
}

.custo-page-WYSIWYG .align-left {
  @apply mb-8 desktop:float-start desktop:mr-10 desktop:mb-8 desktop:w-min;
}

.custo-page-WYSIWYG .align-right {
  @apply mb-8 desktop:float-end desktop:ml-10 desktop:mb-8 desktop:w-min;
}

.custo-page-WYSIWYG .align-left img,
.custo-page-WYSIWYG .align-right img {
  @apply mt-2;
  max-width: revert-layer;
}

@media (max-width: 1024px) {
  .custo-page-WYSIWYG .align-left img,
  .custo-page-WYSIWYG .align-right img {
    @apply w-auto max-w-full;
  }
}

.custo-page-WYSIWYG .align-left figcaption,
.custo-page-WYSIWYG .align-right figcaption,
.custo-page-WYSIWYG .image figcaption {
  @apply text-sm mt-1 text-center;
}

.custo-page-WYSIWYG .button-link {
  @apply py-3 px-4 border border-solid border-primary1 text-primary1 bg-white font-semibold transition-colors rounded-lg;
  @apply hover:bg-primary2 focus:bg-primary2;
}
